<template>
  <div v-loading="loading">
    <div style="display: flex;">
      <div style="flex: 1; padding: 0 20px;">
        <h2>{{flow.name}}</h2>
        <div>
          <el-tag v-if="flow.status === 'PUBLISHED'" type="success" size="mini">Published</el-tag>
          <el-tag v-else-if="flow.status === 'DRAFT'" type="warning" size="mini">Draft</el-tag>
          <el-tag v-else type="info" size="mini">Unknown</el-tag>
          <span style="margin-left: 14px;">
            ID: {{flow.id}}
            <el-tooltip content="Copy" placement="top">
              <el-button size="mini" @click="copyText(flow.id)" icon="el-icon-document-copy" plain circle></el-button>
            </el-tooltip>
          </span>
        </div>
      </div>
      <div style="padding: 0 20px;">
        <el-button icon="el-icon-refresh" circle @click="initFlowJson"></el-button>
        <el-tooltip v-if="flow.status === 'PUBLISHED'" content="Flow cannot be edited once published." placement="bottom">
          <el-button :disabled="true" type="primary" @click="saveChanges">Save Changes</el-button>
        </el-tooltip>
        <el-button v-else type="primary" @click="saveChanges">Save Changes</el-button>
        <el-button @click="$emit('close')">Done</el-button>
        <el-dropdown>
          <el-button style="margin-left: 10px;" icon="el-icon-more" circle></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-copy-document" @click.native="$emit('openCreateModal', { flow, clone: true })">Clone</el-dropdown-item>
            <el-dropdown-item v-if="flow.status === 'PUBLISHED'" icon="el-icon-delete">Deprecate</el-dropdown-item>
            <el-dropdown-item v-else icon="el-icon-delete">Delete</el-dropdown-item>
            <el-dropdown-item v-if="flow.status === 'DRAFT'" icon="el-icon-upload2">Publish</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div style="display: flex;">
      <div style="flex: 1; padding: 20px;">
        <el-button type="primary" icon="el-icon-download" @click="downloadJson" size="small" style="margin-bottom: 14px;">Download JSON</el-button>
        <el-button icon="el-icon-upload" @click="importJson" size="small" style="margin-bottom: 14px;">Import JSON</el-button>
        <el-button type="primary" icon="el-icon-magic-stick" size="small">Import from KeyReply Form Data</el-button>
        <div>
          <advanced-json-editor
            :json.sync="flowJson"
            style="height: 72vh"
            @error="onError"
            @updated="onUpdated"
          ></advanced-json-editor>
        </div>
      </div>
      <div style="flex: 1; padding: 20px;">
        <el-button type="primary" icon="el-icon-top-right" @click="openPreviewInNewTab" size="small" style="margin-bottom: 14px;">Open Preview in New Tab</el-button>
        <iframe
          style="width: 100%; height: 72vh; border: none;"
          :src="selectedFlow?.preview?.preview_url">
        </iframe>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      
    </span>
  </div>
</template>

<script>
import AdvancedJsonEditor from "@/components/Form/AdvancedJsonEditor";

export default {
  name: "FlowBuilder",
  components: {
    AdvancedJsonEditor,
  },
  props: ["flow"],
  data() {
    return {
      loading: false,
      flowJson: {},
    };
  },
  methods: {
    onUpdated(json) {
      this.flowJson = json;
    },
    onError() {
      //
    },
    openPreviewInNewTab() {
      if (this.selectedFlow?.preview?.preview_url) {
        window.open(this.selectedFlow.preview.preview_url, '_blank');
      } else {
        this.$message.error('Preview URL not available.');
      }
    },
    downloadJson() {
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.flowJson));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "flow.json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    importJson() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = '.json';
      fileInput.onchange = (e) => {
        const file = e.target.files[0];
        if (!file) {
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          this.flowJson = JSON.parse(e.target.result);
          this.$message({
            message: "File imported to JSON editor",
            type: "success"
          });
        };
        reader.readAsText(file);
      };
      fileInput.click();
    },
    saveChanges() {
      this.initFlowJson();
    },
    copyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$message({
          message: "Copied to clipboard",
          type: "success"
        });
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
    initFlowJson() {
      this.loading = true;
      this.$store.dispatch("GET_FLOW_JSON", { data: { id: this.flow.id } })
        .finally(() => {
          this.loading = false;
          this.flowJson = this.selectedFlow.json;
        });
    },
  },
  computed: {
    selectedFlow() {
      return this.$store.state.whatsapp?.selectedFlow || {
        json: {},
        previewUrl: {},
      };
    },
  },
  mounted() {
    this.initFlowJson();
  },
};
</script>

<style scoped>
/* Your styles here */
</style>
