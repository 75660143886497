import { rest } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { WhatsAppState } from "./types";

const actions: ActionTree<WhatsAppState, RootState> = {
  REGISTER_WHATSAPP_NUMBER: (store, payload) => {
    return rest("post", "whatsapp_register", payload.data).catch((err) => {
      throw err;
    });
  },
  VERIFY_WHATSAPP_NUMBER: (store, payload) => {
    return rest("post", "whatsapp_verify", payload.data).catch((err) => {
      throw err;
    });
  },
  ADD_WHATSAPP_MESSAGE_TEMPLATES: ({ dispatch }, payload) => {
    return rest("post", "whatsapp_add_message_templates", payload.data)
      .then((data) => {
        dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
          data: { wa_endpoint: payload.data.wa_endpoint },
        });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_WHATSAPP_MESSAGE_TEMPLATES: ({ dispatch }, payload) => {
    return rest("post", "whatsapp_edit_message_templates", payload.data)
      .then((data) => {
        dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
          data: { wa_endpoint: payload.data.wa_endpoint },
        });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_WHATSAPP_MESSAGE_TEMPLATES: ({ dispatch }, payload) => {
    return rest("post", "whatsapp_delete_message_templates", payload.data)
      .then((data) => {
        dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
          data: { wa_endpoint: payload.data.wa_endpoint },
        });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  GET_WHATSAPP_MESSAGE_TEMPLATES: (store, payload) => {
    const refreshServerCache = payload?.refresh;
    if (refreshServerCache) {
      return rest("post", "refresh_message_templates", payload.data)
        .then((data) => {
          store.commit("SET_WHATSAPP_MESSAGE_TEMPLATES", data);
          return data;
        })
        .catch((err) => {
          throw err;
        });
    }
    return rest("post", "whatsapp_get_message_templates", payload.data)
      .then((data) => {
        store.commit("SET_WHATSAPP_MESSAGE_TEMPLATES", data);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  GET_WHATSAPP_APPLICATION_SETTINGS: (store, payload) => {
    return rest("post", "whatsapp_get_application_settings", payload.data)
      .then((data) => {
        return store.commit("SET_WHATSAPP_APPLICATION_SETTINGS", data);
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_WHATSAPP_APPLICATION_SETTINGS: (store, payload) => {
    return rest("post", "whatsapp_update_application_settings", payload.data)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  GET_WHATSAPP_PROFILE_SETTINGS: (store, payload) => {
    return rest("post", "whatsapp_get_profile_settings", payload.data)
      .then((data) => {
        return store.commit("SET_WHATSAPP_PROFILE_SETTINGS", data);
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_WHATSAPP_PROFILE_SETTINGS: (store, payload) => {
    return rest("post", "whatsapp_update_profile_settings", payload.data)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  WHATSAPP_SEND_BULK: (store, payload) => {
    return rest("post", "whatsapp_send_bulk", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_SCHEDULE_BULK: (store, payload) => {
    return rest("post", "whatsapp_schedule_bulk", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_CANCEL_SCHEDULED_BULK: (store, payload) => {
    return rest("post", "whatsapp_cancel_scheduled_bulk", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_REJECT_SCHEDULED_BULK: (store, payload) => {
    return rest("post", "whatsapp_reject_scheduled_bulk", payload.data).catch((err) => {
      throw err;
    });
  },
  CHECK_WHATSAPP_SERVER_STATUS: (store, payload) => {
    return rest("post", "whatsapp_get_server_status", payload.data)
      .then((data) => {
        store.commit("SET_WHATSAPP_SERVER_STATUS", data);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  WHATSAPP_CREATE_GROUP: (store, payload) => {
    return rest("post", "whatsapp_create_group", payload.data)
      .then((data) => {
        store.dispatch("WHATSAPP_GET_GROUPS", {
          data: { wa_endpoints: [payload.data.wa_endpoint] },
        });
        return;
      })
      .catch((err) => {
        throw err;
      });
  },
  WHATSAPP_GET_GROUPS: (store, { data, commit }) => {
    let payloadData = data;
    if (!payloadData) {
      payloadData = {};
    }
    return rest("post", "whatsapp_get_groups", payloadData)
      .then((data) => {
        if (commit) {
          store.commit("SET_WHATSAPP_GROUPS", data);
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  WHATSAPP_GENERATE_INVITE_LINK: (store, payload) => {
    return rest("post", "whatsapp_generate_invite_link", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_DELETE_INVITE_LINK: (store, payload) => {
    return rest("post", "whatsapp_delete_invite_link", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_LEAVE_GROUP: (store, payload) => {
    return rest("post", "whatsapp_leave_group", payload.data)
      .then((data) => {
        store.dispatch("WHATSAPP_GET_GROUPS", {
          data: { wa_endpoints: [payload.data.wa_endpoint] },
        });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  WHATSAPP_DELETE_GROUP_PARTICIPANTS: (store, payload) => {
    return rest("post", "whatsapp_delete_group_participants", payload.data)
      .then((data) => {
        store.dispatch("WHATSAPP_GET_GROUPS", {
          data: { wa_endpoints: [payload.data.wa_endpoint] },
        });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  WHATSAPP_ADD_GROUP_ADMINS: (store, payload) => {
    return rest("post", "whatsapp_add_group_admins", payload.data)
      .then((data) => {
        store.dispatch("WHATSAPP_GET_GROUPS", {
          data: { wa_endpoints: [payload.data.wa_endpoint] },
        });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  WHATSAPP_REMOVE_GROUP_ADMINS: (store, payload) => {
    return rest("post", "whatsapp_delete_group_admins", payload.data)
      .then((data) => {
        store.dispatch("WHATSAPP_GET_GROUPS", {
          data: { wa_endpoints: [payload.data.wa_endpoint] },
        });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  WHATSAPP_SEND_MESSAGE: (store, payload) => {
    return rest("post", "whatsapp_send_message", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_UPDATE_GROUP_ICON: (store, payload) => {
    return rest("post", "whatsapp_update_group_icon", payload.data)
      .then(() => {
        store.dispatch("WHATSAPP_GET_GROUPS", {
          data: { wa_endpoints: [payload.data.wa_endpoint] },
        });
        return;
      })
      .catch((err) => {
        throw err;
      });
  },
  GET_WHATSAPP_SERVERS: (store, payload) => {
    return rest("get", "whatsapp_get_servers", {})
      .then((data) => {
        store.commit("SET_WHATSAPP_SERVERS", data);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  GET_ACCOUNT_ANALYTICS: (store, payload) => {
    return rest("post", "whatsapp_get_account_analytics", payload.data).catch((err) => {
      throw err;
    });
  },
  GET_MESSAGE_TEMPLATE_ANALYTICS: (store, payload) => {
    return rest("post", "whatsapp_get_message_template_analytics", payload.data).catch((err) => {
      throw err;
    });
  },
  GET_MESSAGE_LOGS: (store, payload) => {
    return rest("post", "whatsapp_get_message_logs", payload.data).catch((err) => {
      throw err;
    });
  },
  GET_MESSAGE_LOGS_WITH_STATUS: (store, payload) => {
    return rest("post", "whatsapp_get_message_logs_with_status", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_GET_SCHEDULE_BULK: (store, payload) => {
    return rest("post", "whatsapp_get_schedule_bulk", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_GET_SENT_SCHEDULE_BULK: (store, payload) => {
    return rest("post", "whatsapp_get_sent_schedule_bulk", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_GET_SINGLE_SCHEDULE_BULK: (store, payload) => {
    return rest("post", "whatsapp_get_single_schedule_bulk", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_DELETE_PROFILE_PHOTO: (store, payload) => {
    return rest("post", "whatsapp_delete_profile_photo", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_GET_IGNORED_NUMBERS: (store, payload) => {
    return rest("post", "whatsapp_get_ignored_numbers", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_GET_MEDIA_BY_ID: (store, payload) => {
    return rest("post", "whatsapp_get_media_by_id", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_UPLOAD_PROFILE_PHOTO_CLOUD: (store, payload) => {
    const formData = new FormData();
    formData.append("file", payload);
    return rest("post", "whatsapp_cloud_api_business_profile_picture_handle", formData).catch(
      (err) => {
        throw err;
      }
    );
  },
  WHATSAPP_CLOUD_UPLOAD_MEDIA: (store, payload) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("wa_id", payload.wa_id); // Add wa_id to the form data

    return rest("post", `whatsapp_cloud_api_media_upload`, formData).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_CLOUD_GET_MEDIA_BY_ID: (store, payload) => {
    return rest("post", "whatsapp_cloud_api_get_media_by_id", payload.data).catch((err) => {
      throw err;
    });
  },
  WHATSAPP_ADD_NOTE: (store, payload) => {
    return rest("post", "whatsapp_add_note", payload).catch((err) => {
      throw err;
    });
  },
  GET_FLOWS: (store, payload) => {
    const refreshServerCache = payload?.refresh;
    if (refreshServerCache) {
      return rest("post", "whatsapp_refresh_flows", payload.data)
        .then((data) => {
          store.commit("SET_FLOWS", data);
          return data;
        })
        .catch((err) => {
          throw err;
        });
    }
    return rest("post", "whatsapp_get_flows", payload.data)
      .then((data) => {
        store.commit("SET_FLOWS", data);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  GET_FLOW_JSON: (store, payload) => {
    return rest("post", "whatsapp_get_flow_json", payload.data)
      .then((data) => {
        store.commit("SET_SELECTED_FLOW", data);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default actions;
