import { WhatsAppState } from "./types";

const state: WhatsAppState = {
  templates: [],
  application_settings: {},
  profile_settings: {},
  server_status: false,
  groups: [],
  servers: [],
  flows: {
    data: [],
    total: 0,
  },
  selectedFlow: {
    preview: {},
    json: {},
  },
};

export default state;
